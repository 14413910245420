@charset "UTF-8";

@font-face {
  font-family: "gaxon";
  src: url("fonts/gaxon.eot");
  src: url("fonts/gaxon.eot?#iefix") format("embedded-opentype"),
  url("fonts/gaxon.woff") format("woff"),
  url("fonts/gaxon.ttf") format("truetype"),
  url("fonts/gaxon.svg#gaxon") format("svg");
  font-weight: normal;
  font-style: normal;

}
body{
  font-family: 'Cairo', sans-serif !important;
}


@font-face {
  font-family: main-arabic;
src: url('fonts/main-arabic.ttf');
}

[data-icon]:before {
  font-family: "gaxon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "gaxon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\61";
}

.icon-add-circle:before {
  content: "\62";
}

.icon-affix:before {
  content: "\63";
}

.icon-alert:before {
  content: "\64";
}

.icon-alert-new:before {
  content: "\65";
}

.icon-all-contacts:before {
  content: "\66";
}

.icon-amchart:before {
  content: "\67";
}

.icon-anchor:before {
  content: "\68";
}

.icon-apps:before {
  content: "\69";
}

.icon-apps-new:before {
  content: "\6a";
}

.icon-arrow-left:before {
  content: "\6b";
}

.icon-arrow-right:before {
  content: "\6c";
}

.icon-attachment:before {
  content: "\6d";
}

.icon-auth-screen:before {
  content: "\6e";
}

.icon-autocomplete:before {
  content: "\6f";
}

.icon-avatar:before {
  content: "\70";
}

.icon-backtop:before {
  content: "\71";
}

.icon-badge:before {
  content: "\72";
}

.icon-basic-calendar:before {
  content: "\73";
}

.icon-birthday:before {
  content: "\74";
}

.icon-birthday-new:before {
  content: "\75";
}

.icon-bitcoin:before {
  content: "\76";
}

.icon-breadcrumb:before {
  content: "\77";
}

.icon-burger:before {
  content: "\78";
}

.icon-button:before {
  content: "\79";
}

.icon-calendar:before {
  content: "\7a";
}

.icon-calendar-new:before {
  content: "\41";
}

.icon-callout:before {
  content: "\42";
}

.icon-camera:before {
  content: "\43";
}

.icon-camera-2:before {
  content: "\44";
}

.icon-card:before {
  content: "\45";
}

.icon-cards-list-view:before {
  content: "\46";
}

.icon-carousel:before {
  content: "\47";
}

.icon-cascader:before {
  content: "\48";
}

.icon-chart:before {
  content: "\49";
}

.icon-chart-area:before {
  content: "\4a";
}

.icon-chart-area-new:before {
  content: "\4b";
}

.icon-chart-bar:before {
  content: "\4c";
}

.icon-chart-composed:before {
  content: "\4d";
}

.icon-chart-line:before {
  content: "\4e";
}

.icon-chart-pie:before {
  content: "\4f";
}

.icon-chart-radar:before {
  content: "\50";
}

.icon-chart-radial:before {
  content: "\51";
}

.icon-chart-scatter:before {
  content: "\52";
}

.icon-chart-tree:before {
  content: "\53";
}

.icon-charvlet-down:before {
  content: "\54";
}

.icon-charvlet-left:before {
  content: "\55";
}

.icon-charvlet-right:before {
  content: "\56";
}

.icon-charvlet-up:before {
  content: "\57";
}

.icon-chat:before {
  content: "\58";
}

.icon-chat-bubble:before {
  content: "\59";
}

.icon-chat-new:before {
  content: "\5a";
}

.icon-check:before {
  content: "\30";
}

.icon-check-circle-o:before {
  content: "\31";
}

.icon-check-cricle:before {
  content: "\32";
}

.icon-check-square:before {
  content: "\33";
}

.icon-check-square-o:before {
  content: "\34";
}

.icon-chevron-down:before {
  content: "\35";
}

.icon-chevron-left:before {
  content: "\36";
}

.icon-chevron-right:before {
  content: "\37";
}

.icon-chevron-up:before {
  content: "\38";
}

.icon-circle:before {
  content: "\39";
}

.icon-circle-o:before {
  content: "\21";
}

.icon-ckeditor:before {
  content: "\22";
}

.icon-close:before {
  content: "\23";
}

.icon-close-circle:before {
  content: "\24";
}

.icon-collapse:before {
  content: "\25";
}

.icon-company:before {
  content: "\26";
}

.icon-components:before {
  content: "\27";
}

.icon-compose:before {
  content: "\28";
}

.icon-contacts:before {
  content: "\29";
}

.icon-copy:before {
  content: "\2a";
}

.icon-crm:before {
  content: "\2b";
}

.icon-crypto:before {
  content: "\2c";
}

.icon-culture-calendar:before {
  content: "\2d";
}

.icon-custom-view:before {
  content: "\2e";
}

.icon-dasbhoard:before {
  content: "\2f";
}

.icon-data-display:before {
  content: "\3a";
}

.icon-data-entry:before {
  content: "\3b";
}

.icon-datepicker:before {
  content: "\3c";
}

.icon-default-timeline:before {
  content: "\3d";
}

.icon-diamond:before {
  content: "\3e";
}

.icon-divider:before {
  content: "\3f";
}

.icon-donut:before {
  content: "\40";
}

.icon-down:before {
  content: "\5b";
}

.icon-draft:before {
  content: "\5d";
}

.icon-drag-and-drop:before {
  content: "\5e";
}

.icon-dropdown:before {
  content: "\5f";
}

.icon-edit:before {
  content: "\60";
}

.icon-editor:before {
  content: "\7b";
}

.icon-ellipse-h:before {
  content: "\7c";
}

.icon-ellipse-v:before {
  content: "\7d";
}

.icon-email:before {
  content: "\7e";
}

.icon-error:before {
  content: "\5c";
}

.icon-error-404:before {
  content: "\e000";
}

.icon-error-500:before {
  content: "\e001";
}

.icon-etherium:before {
  content: "\e002";
}

.icon-exclamation:before {
  content: "\e003";
}

.icon-expand:before {
  content: "\e004";
}

.icon-extensions:before {
  content: "\e005";
}

.icon-extra-components:before {
  content: "\e006";
}

.icon-eye:before {
  content: "\e007";
}

.icon-facebook:before {
  content: "\e008";
}

.icon-family:before {
  content: "\e009";
}

.icon-feedback:before {
  content: "\e00a";
}

.icon-files:before {
  content: "\e00b";
}

.icon-filter:before {
  content: "\e00c";
}

.icon-filter-circle:before {
  content: "\e00d";
}

.icon-folder:before {
  content: "\e00e";
}

.icon-folder-o:before {
  content: "\e00f";
}

.icon-font:before {
  content: "\e010";
}

.icon-forgot-password:before {
  content: "\e011";
}

.icon-forward:before {
  content: "\e012";
}

.icon-forward-o:before {
  content: "\e013";
}

.icon-frequent:before {
  content: "\e014";
}

.icon-geo-location:before {
  content: "\e015";
}

.icon-graduation:before {
  content: "\e016";
}

.icon-growth:before {
  content: "\e017";
}

.icon-home:before {
  content: "\e018";
}

.icon-hotel-booking:before {
  content: "\e019";
}

.icon-icon:before {
  content: "\e01a";
}

.icon-image:before {
  content: "\e01b";
}

.icon-important:before {
  content: "\e01c";
}

.icon-important-o:before {
  content: "\e01d";
}

.icon-inbox:before {
  content: "\e01e";
}

.icon-inbuilt-apps:before {
  content: "\e01f";
}

.icon-input:before {
  content: "\e020";
}

.icon-inputnumber:before {
  content: "\e021";
}

.icon-invert-color:before {
  content: "\e022";
}

.icon-keyboard:before {
  content: "\e023";
}

.icon-like:before {
  content: "\e024";
}

.icon-like-o:before {
  content: "\e025";
}

.icon-link:before {
  content: "\e026";
}

.icon-lising-dbrd:before {
  content: "\e027";
}

.icon-list-select-o:before {
  content: "\e028";
}

.icon-listing-dbrd:before {
  content: "\e029";
}

.icon-litcoin:before {
  content: "\e02a";
}

.icon-localeprovider:before {
  content: "\e02b";
}

.icon-location:before {
  content: "\e02c";
}

.icon-lock-screen:before {
  content: "\e02d";
}

.icon-long-arrow:before {
  content: "\e02e";
}

.icon-long-arrow-down:before {
  content: "\e02f";
}

.icon-long-arrow-left:before {
  content: "\e030";
}

.icon-long-arrow-right:before {
  content: "\e031";
}

.icon-long-arrow-up:before {
  content: "\e032";
}

.icon-mail-open:before {
  content: "\e033";
}

.icon-map-clustering:before {
  content: "\e034";
}

.icon-map-directions:before {
  content: "\e035";
}

.icon-map-drawing:before {
  content: "\e036";
}

.icon-map-event-listener:before {
  content: "\e037";
}

.icon-map-google:before {
  content: "\e038";
}

.icon-map-km-layer:before {
  content: "\e039";
}

.icon-map-overlay:before {
  content: "\e03a";
}

.icon-map-popup-info:before {
  content: "\e03b";
}

.icon-map-selectable:before {
  content: "\e03c";
}

.icon-map-simple:before {
  content: "\e03d";
}

.icon-map-street-view:before {
  content: "\e03e";
}

.icon-map-styled:before {
  content: "\e03f";
}

.icon-map-traffic-layer:before {
  content: "\e040";
}

.icon-megaphone:before {
  content: "\e041";
}

.icon-mention:before {
  content: "\e042";
}

.icon-menu:before {
  content: "\e043";
}

.icon-menu-down:before {
  content: "\e044";
}

.icon-menu-fold:before {
  content: "\e045";
}

.icon-menu-left:before {
  content: "\e046";
}

.icon-menu-lines:before {
  content: "\e047";
}

.icon-menu-right:before {
  content: "\e048";
}

.icon-menu-select:before {
  content: "\e049";
}

.icon-menu-unfold:before {
  content: "\e04a";
}

.icon-menu-up:before {
  content: "\e04b";
}

.icon-message:before {
  content: "\e04c";
}

.icon-mic:before {
  content: "\e04d";
}

.icon-modal:before {
  content: "\e04e";
}

.icon-navigation:before {
  content: "\e04f";
}

.icon-noodles:before {
  content: "\e050";
}

.icon-notification:before {
  content: "\e051";
}

.icon-notification-new:before {
  content: "\e052";
}

.icon-orders:before {
  content: "\e053";
}

.icon-pagination:before {
  content: "\e054";
}

.icon-phone:before {
  content: "\e055";
}

.icon-picker:before {
  content: "\e056";
}

.icon-pizza:before {
  content: "\e057";
}

.icon-plain-list-divider:before {
  content: "\e058";
}

.icon-plain-list-view:before {
  content: "\e059";
}

.icon-popconfirm:before {
  content: "\e05a";
}

.icon-popover:before {
  content: "\e05b";
}

.icon-popup-calendar:before {
  content: "\e05c";
}

.icon-pricing-table:before {
  content: "\e05d";
}

.icon-product-grid:before {
  content: "\e05e";
}

.icon-product-list:before {
  content: "\e05f";
}

.icon-profile:before {
  content: "\e060";
}

.icon-profile2:before {
  content: "\e061";
}

.icon-progress:before {
  content: "\e062";
}

.icon-queries:before {
  content: "\e063";
}

.icon-question-circle:before {
  content: "\e064";
}

.icon-quote-backward:before {
  content: "\e065";
}

.icon-quote-forward:before {
  content: "\e066";
}

.icon-radiobutton:before {
  content: "\e067";
}

.icon-refer:before {
  content: "\e068";
}

.icon-rendaring-calendar:before {
  content: "\e069";
}

.icon-reply:before {
  content: "\e06a";
}

.icon-reply-o:before {
  content: "\e06b";
}

.icon-reset-password:before {
  content: "\e06c";
}

.icon-revenue-new:before {
  content: "\e06d";
}

.icon-ripple:before {
  content: "\e06e";
}

.icon-schedule:before {
  content: "\e06f";
}

.icon-search:before {
  content: "\e070";
}

.icon-search-new:before {
  content: "\e071";
}

.icon-select:before {
  content: "\e072";
}

.icon-selectable-map:before {
  content: "\e073";
}

.icon-sent:before {
  content: "\e074";
}

.icon-setting:before {
  content: "\e075";
}

.icon-shopping-cart:before {
  content: "\e076";
}

.icon-shuffle:before {
  content: "\e077";
}

.icon-signin:before {
  content: "\e078";
}

.icon-signup:before {
  content: "\e079";
}

.icon-slider:before {
  content: "\e07a";
}

.icon-social:before {
  content: "\e07b";
}

.icon-spam:before {
  content: "\e07c";
}

.icon-spin:before {
  content: "\e07d";
}

.icon-star:before {
  content: "\e07e";
}

.icon-star-half:before {
  content: "\e07f";
}

.icon-star-o:before {
  content: "\e080";
}

.icon-stats:before {
  content: "\e081";
}

.icon-steps:before {
  content: "\e082";
}

.icon-styled-map:before {
  content: "\e083";
}

.icon-sweet-alert:before {
  content: "\e084";
}

.icon-switch:before {
  content: "\e085";
}

.icon-tab:before {
  content: "\e086";
}

.icon-table:before {
  content: "\e087";
}

.icon-table-data:before {
  content: "\e088";
}

.icon-table-general:before {
  content: "\e089";
}

.icon-tag:before {
  content: "\e08a";
}

.icon-tag-new:before {
  content: "\e08b";
}

.icon-tag-o:before {
  content: "\e08c";
}

.icon-tasks:before {
  content: "\e08d";
}

.icon-team:before {
  content: "\e08e";
}

.icon-testimonial:before {
  content: "\e08f";
}

.icon-thumb-up:before {
  content: "\e090";
}

.icon-thumbs-down:before {
  content: "\e091";
}

.icon-ticket-new:before {
  content: "\e092";
}

.icon-tickets:before {
  content: "\e093";
}

.icon-timeline:before {
  content: "\e094";
}

.icon-timeline-left-align:before {
  content: "\e095";
}

.icon-timeline-new:before {
  content: "\e096";
}

.icon-timeline-with-icons:before {
  content: "\e097";
}

.icon-timepicker:before {
  content: "\e098";
}

.icon-tooltip:before {
  content: "\e099";
}

.icon-transfer:before {
  content: "\e09a";
}

.icon-translation:before {
  content: "\e09b";
}

.icon-trash:before {
  content: "\e09c";
}

.icon-tree:before {
  content: "\e09d";
}

.icon-treeselect:before {
  content: "\e09e";
}

.icon-uncheck-squire:before {
  content: "\e09f";
}

.icon-uncheck-squire-o:before {
  content: "\e0a0";
}

.icon-upload:before {
  content: "\e0a1";
}

.icon-user:before {
  content: "\e0a2";
}

.icon-user-o:before {
  content: "\e0a3";
}

.icon-view:before {
  content: "\e0a4";
}

.icon-view-o:before {
  content: "\e0a5";
}

.icon-visits:before {
  content: "\e0a6";
}

.icon-wall:before {
  content: "\e0a7";
}

.icon-widgets:before {
  content: "\e0a8";
}

.icon-wysiwyg:before {
  content: "\e0a9";
}



.sidebar .nav-item.nav-profile .nav-link {
  height: auto;
}

.sidebar .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  color: #686868;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
}

.sidebar .nav-item.nav-profile .profile-image {
  position: relative;
  width: 40px;
  height: 40px;
}
.sidebar .nav-item.nav-profile .profile-image img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.sidebar .nav-item {
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  margin-bottom: 5px;
  list-style: none;

}

.sidebar  .nav-item.nav-profile .profile-name {
  text-align: left;
  margin-left: .75rem;
}
.sidebar .nav-item.nav-profile .profile-name .name {
  color: #000000;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;

}
.sidebar  .nav-item.nav-profile .profile-name .designation {
  color: #686868;
  font-size: .75rem;
}

.chart-wrapper {
  width: 100%;
}

a.gx-site-logo img {
  margin: 0px auto;
  display: block;
  text-align: center;
  max-width: 60%;
  height: 70%;
  object-fit: contain;
}
li.ant-menu-submenu.ant-menu-submenu-inline.sidebar-sub-menu .ant-menu-submenu-title{
  padding-left:8px !important;
}
i.ant-menu-submenu-arrow{
  right:28px !important;
}
button.ant-btn.gx-btn-cyan {
  border-radius: 20px;
  margin-right: 4px;
}



.gx-lt-icon-search-bar-lg {
  margin: 0 !important;
}

ul.ant-menu.ant-menu-lite.ant-menu-root.ant-menu-inline {
  background: transparent;
  color: #000;
}




.logo-img {
  width: 38px;
  display: inline-block;
  margin-right: 15px;
}

span.gx-avatar-name {
  width: 100%;
  background: rgba(200,200,200,.2);
  background-repeat: repeat-x;
  border-radius: 20px;
  padding: 11px 0;
  text-indent: 23px;
  font-size: 13px;
  /* margin: 2px; */
}
aside.gx-app-sidebar.null.ant-layout-sider.ant-layout-sider-lite {
  /* background-image: -webkit-linear-gradient(45deg,#1488cc,#2b32b2); */
  box-shadow: 2px 10px 30px 0 rgba(0,0,0,.42), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2) !important;
  z-index: 888888 !important;

}

.ant-menu-submenu > .ant-menu {
  background-color: transparent !important;
  border-radius: 0;
  /* color: red !important; */
}

button.ant-btn.ant-btn-primary {

    border: 0 !important;
    color: #ffffff !important;
    border-color: transparent;
    background-size: cover;
    background-repeat: repeat;    padding: 0 20px;
    border-radius: 30px;
    font-size: 13px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}


li.ant-menu-item {
  padding-right: 0px;
  padding-left: 80px;
}

.gx-mb-2, .gx-my-2 {
  margin-bottom: 0.5rem !important;
  text-align: left;
}

.ant-menu-item > a:hover {
  transition: 0.4s;
  font-weight: bold !important;

}
li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open a {
  color: #ffffff !important;
  left: 0;
  float: left;
  width: 100%;
  text-align: left !important;
}
.ant-menu-sub > li > a {
  text-align: left !important;
}
.customestyle .ant-menu {
  color: #545454;
  background: transparent !important;
}

label.gx-mb-1.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 11px;
}
.ant-menu-submenu-title:hover {
  background: rgba(200,200,200,.2);
  transition: 0.4s;
}

.ant-layout-sider .ant-menu .ant-menu-submenu-title span, .ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
  color: rgba(255, 255, 255, 0.84);
}

header.ant-layout-header {  /* 1  */
  background-color: #fff !important;
}

.col.about-dashboard { /*  2 */
  padding: 25px 32px 10px;

  background: #fafafc !important;
}

.gx-main-content-wrapper { /* 3 */
  padding: 32px 32px 0;
  flex: 1 1;
  background: #f0f2f6!important;
}

.ant-card{  /*  4 */
  background: #fff !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25); 
}

aside.gx-app-sidebar.null.ant-layout-sider.ant-layout-sider-lite   {
  color: #ffffff;
  background-position: bottom;

}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  background: #212121;
}

.ant-menu-submenu-title:hover{

background:rgba(200,200,200,.2);
   transition: all 0.4s ease !important;
  
}
.gx-layout-sider-scrollbar > div{
  padding-bottom:10px;
}
.col.about-dashboard h2  /* 6  */ {
  font-size: 19px;
  margin: 0 0px;
  margin-bottom: 6px;
  color: #000;
  font-weight: bold;
}


.col.about-dashboard p   /* 7  */ {
  font-size: 14px;
  color:#888888;
  
 }

 p.header-color-secondary  /* 8  */  {
  margin: 0;
  font-size: 0.9em;
  color: #888888;

}
h6.header-color-primary   /* 9  */ {
  font-size: 1.2em;
  color: #000000;
  margin: 0;
  

}

.gx-header-notifications > li  /* 9  */ {
  font-size: 18px;
  color: #888888 !important;
}

.gx-bg-dark-primary:before  /* 10  */ {
  background: unset !important;
}

.ant-card.gx-card-widget.gx-bg-dark-primary.ant-card-bordered   /* 11  */{
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
}
.chart-container {
  position: relative;
}
.chart-wrapper {
  position: relative;
}
.charts-total {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 62px;
  text-align: center;
  transform: translate(-50%, -50%);
}
.charts-total .pie-sub-title{
  height: 22px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.pie-list {
  min-width: 200px;
  margin: 0 20px;
  padding: 0;
  list-style: none;
  text-align: left;
}
.full-width-radio.ant-radio-group.ant-radio-group-outline.full-width-radio{
  width:100%;
  display:flex;
  flex-direction: row;
}

.full-width-radio .ant-radio-button-wrapper{
  width:100%;
}
.pie-list li {
  height: 22px;
  margin-bottom: 16px;
  line-height: 22px;
  cursor: pointer;
  position: relative;
}
.pie-list-value {
  position: absolute;
  right: 0;
}
.pie-list-dot{
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 8px;
}
a.sign-button   /* 12  */ {
  background-image: -moz-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  background-image: -ms-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  border-color: transparent;
  background-color: #4a19c8 !important;
  border-radius: 25px;
  border: 0;
  padding-left: 25px;
  margin-top: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  padding: 6px 40px;
  font-weight: bold;
  width: 120px;
  margin-right:4px;
}
a.sign-button.second {
  padding: 6px 25px;
}

.sign-form button.ant-btn.gx-mb-0.ant-btn-primary {
  /* padding: 6px 20px; */
  font-weight: bold;
  width: 120px;
  margin: 0;
  font-size: 14px;
  margin-left: 8px;
}
.gx-app-logo-content-bg {
  color: #ffffff;
  overflow-y: auto;
  background-position: bottom;
  background-size: cover;
  background-image: url("https://pixinvent.com/demo/convex-angular-bootstrap-admin-dashboard-template/demo-3/assets/img/sidebar-bg/08.jpg") !important;

}
.gx-app-logo-content-bg:before {

  background-color: #192245 !important;
}

.gx-app-logo-content-bg:before {
  background-color: #192245db !important;
}

.ant-card-bordered {
  border: none  !important;
  border-radius: 0.375rem !important;
}

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  margin-right: 0;
}
li.ant-menu-item.ant-single-menu.ant-menu-submenu.ant-menu-submenu-inline.sidebar-sub-menu.ant-menu-item-selected{
  background:black;
}
.ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-inline .ant-menu-item:after{
  border-right: 5px solid #de0303 !important;
}
.icon-style {
  font-size: 24px !important;
  margin-left: 11px;
  line-height: 30px !important;
  margin-top: 8px;
  color:white !important;
}

 .gx-linebar {
  display: block !important;
  font-size: 20px;
  color: white !important;
}
.ant-menu {
  color: #545454;
  background: transparent;
}

.gx-d-none {
  display: block !important;
}

@media screen and (min-width: 992px){
  .gx-d-lg-none {
    display: block !important;
}
}
li.ant-menu-item.ant-single-menu.ant-menu-submenu.ant-menu-submenu-inline.sidebar-sub-menu{
  padding:0 !important;
}
.ant-menu-submenu-title{
  text-align: left;
}
.ant-single-menu span{
  display:inline-flex !important;
  color:white;
}
.ant-tabs-ink-bar {
  bottom: auto;
}
.ant-tabs-bar {
  border-bottom: none;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 40px;
  padding-left: 40px;
}
.ant-tabs-tab-prev-icon::before {
  position: relative;
  left: 6px;
}
.ant-tabs-tab-next-icon::before {
  position: relative;
  right: 6px;
}
.user-information {
  float: left;
  margin-right: 14px;
  margin-left: 20px;
  margin-top: 0;
  margin-top: 3px;
}
  li.gx-user-nav{
    list-style: none !important;


    }
.gx-avatar {
  float: right;
  width: 40px !important;
  height: 40px !important;
  display: inline-block !important;
  margin-right:20px !important;
}
.gx-header-notifications > li.gx-notify {
  margin-left:  22px;
}

.ant-calendar-picker-icon {
  display: inline-block;
  color: rgb(255, 255, 255) !important;
  font-size: 19px !important;
  line-height: 1;
  left: 18px !important;
  top: 15px !important;
}
.gx-status-pos .gx-status.gx-orange{
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;

}
input.ant-calendar-picker-input.ant-input {
  /* background: transparent; */
  height:36px;
  width:100%;
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  border: 0;
  color: #fff !important;
  background-color: #8226f5 !important;
  border-radius: 25px;
  text-align: right !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    transition: ease all 0.3s;
    -webkit-transition: ease all 0.3s;
    -moz-transition: ease all 0.3s;
    -ms-transition: ease all 0.3s;
    text-align: left !important;
    padding-left: 57px;

}
.show-info {
  float: left;
  margin: 0;
}

.about-holder{
  float: left;
  text-align: left;
}

input.ant-calendar-picker-input.ant-input::-webkit-input-placeholder {
  color:#fff; 
  font-size: 14px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

button.ant-btn.important-button {
  color: #ffffff !important;
  background-image: -moz-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  background-image: -ms-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  border-color: transparent;
  background-color: #4a19c8 !important;
  border-radius: 25px;
  border: 0;
  padding-left: 25px;
  margin-top: 15px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}


i.gx-icon-btn.icon.icon-menu-fold {
  width: 50px;
  height: 100%;
  font-size: 24px;
}

input.ant-input {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  transition: ease all 0.3s;
  -webkit-transition: ease all 0.3s;
}

.ant-layout-footer {
  border-top: solid 1px #e8e8e821 !important;
}

footer.ant-layout-footer {
  background: #ffffff !important;
  color:rgb(50, 50, 50) !important;
}
.gx-mt-0, .gx-my-0 {
  margin-top: 0 !important;
  text-align: left;
}
label.gx-mb-1.ant-radio-button-wrapper {
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
}
.customestyle {
  height:20%;
}
.gx-sidebar-content.customestyle{
  height:80%;
  border-right:0;
}
.gx-sidebar-notifications {
  padding: 0 10px 10px !important;
  margin: 0 20px 10px;
}

.gx-text-primary{
  color: #ffffff !important;

}

li.ant-menu-submenu.ant-menu-submenu-inline.primary-active .ant-menu-submenu-title {
  background-image: -moz-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  background-image: -ms-linear-gradient(45deg, #8226f5 0%, #e625ea 100%) !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  /* margin: 10px 0; */
}

.gx-btn-cyan, a.gx-btn-cyan {
  color: #ffffff !important;
  background-color: #13c2c2 !important;
  background-image: -moz-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  background-image: -webkit-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  background-image: -ms-linear-gradient(45deg, #4a19c8 0%, #7979ff 100%) !important;
  border-color: transparent;
  background-color: #4a19c8 !important;
  border-radius: 25px;
  border: 0;
  padding-left: 25px;
  /* margin-top: 15px; */
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
}

.ant-col.ant-form-item-label.ant-col-xs-24.ant-col-sm-8 {
  text-align: left;
}

li.gx-user-nav {
  margin-right: 0px !important; 
}

.gx-lt-icon-search-bar-lg .gx-search-icon {
  left: auto !important;
  right: 0 !important;
}
.gx-lt-icon-search-bar-lg input[type='search'] {
  padding: 10px 10px 10px 10px !important;
}

.gx-search-bar input[type='search'] {
  text-align: left !important;
}

label.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after{
  background: #df3c31 !important;
  background-image: none !important;
}

.ant-menu-inline-collapsed li.ant-menu-submenu.ant-menu-submenu-inline.sidebar-sub-menu .ant-menu-submenu-title{
  padding-top:5px !important;
  padding-left:28px !important;
}

.ranking-sales-card{
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  margin-right: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color:#f5f5f5 ;
  border-radius: 20px;
  color:rgba(0, 0, 0, 0.65);
}
.ranking-sales-card.active{
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  margin-right: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color:rgba(0, 0, 0, 0.65) ;
  border-radius: 20px;
  color:#f5f5f5;
}

.rankingTitle {
  margin-top: 16px;
  color:rgba(0, 0, 0, 0.85);
  font-weight:500;
}
.ranking-sales-card.active{
  color: #fff;
  background-color: #314659;
}
.list-sales li.active .ranking-sales-card.active{
}
.list-sales li span:first-child {
  margin-right: 16px;
}

.ant-radio-group .ant-radio-button-wrapper{
  text-align: center;
}
.list-sales {
  text-align: left;
  margin: 0;
  padding: 16px;
  list-style: none;
}
.list-sales li {
  zoom: 1;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: -15px;
  cursor: pointer;
 }
 .list-sales li:hover
{
  background-color:#f5f5f5;
  color:#314659 ;
 
}
.list-sales li.active
{
color:#314659;
   background-color:#f7f7f7 ;
 
}
.rankingItemTitle {
  flex: 1;
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rankingItemPrice {
  
  margin-right: 0;
}
.detailed-customer-product{
  margin-bottom: 10%;
  text-align: center;
}
 .list-data
 {
 
 
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 30px;;
  text-align: left;
  padding-top: 20px;
  font-size: 14px;
  color:#314659 ;
  background-color:#f7f7f7;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 10px;
  /* cursor: pointer; */
  

 }
 .product-info{
  /* border-bottom: 1px solid #9f9a9a; */
  text-align: left;
  padding-left: 77px;
     padding-top: 3px;
     padding-bottom: 3px 
 }
 
 .Total-Socks{
    text-align: center;
    font-size: 20px;
    margin-top: 80px !important;  
 }
  .total-style div{
    font-size: 40px
  }
  .salesExtra {
    display: inline-block;
    margin-right: 24px;
  }
  .salesExtra a{
      margin-left: 24px;
      color: black;
  }
  .salesExtra a:hover{
    
      color: #1890FF;
    
  }
  .radio-date{
    
    position: relative;
    display: inline-block;
    padding-top: 2px !important;
    height: 40px !important;
    margin: 0;
    padding: 0 15px;
    color: #545454;
    line-height: 34px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
   }
   /* .cascader-picker.ant-fullcalendar-month-select .ant-select-selection__rendered{

     width: 10px !important;
   } */
   /* .cascader-pickerant-fullcalendar-year-select {
    width: 76px !important;

   } */
   .cascader-picker .ant-select-selection--single {
    position: relative;
    height: 36px;
    cursor: pointer;
    width: 78px !important;
}
   .select-form  {
      position: relative;
      height: 36px;
      cursor: pointer;
      width: 305px !important;
  }   
  .ant-select-selection--single {
    position: relative;
    height: 36px;
    cursor: pointer;
    /* width: 660px !important; */
}
.ant-fullcalendar td {
  position: relative;
  height: 100% !important;
}
.li-content .ant-fullcalendar-fullscreen .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 140px !important;
  margin: 0 4px;
  padding: 4px 8px;
  color: #9eb7bd;
  text-align: left;
  border-top: 2px solid #e8e8e8;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  font-size: 9px !important;
  /* background-color:  #ccddff; */
}
.li-content{
  background-color:  #ccddff;
  font-size: 8px;
  }
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 100% !important;
  overflow-y: auto;
 width: 100px !important;
}
 
.span-content {
  padding-left: 8px;
  border-left:  #b3ccff 4px solid;
   padding-bottom: 2px !important;
  font-size: 8px;
  padding-top: 4px !important;
box-shadow: 2px 2px 2px 2px white;
font-weight: bold; 
  }
  

  .span-content:hover {
  /* box-shadow:2px 2px 2px 2px #000; */
  border-style: solid;
  background-color:#6699ff  ;
  border-width: 0.3em  ;
  border-color:white;
  color:white;
  font-weight: bold;
  font-size: 9px;
  padding-left: 3px

    }


    .branch-reports  .ant-select-selection{
      position: relative;
      height: 40px;
      cursor: pointer;
      width: 150px !important;
     
    }
    .rangePicker-reports .ant-input-lg {
      height: 40px;
       font-size: 16px;
      width: 250px !important;
      margin-right: 111px;

 
  }
  .payments-visa-cash{
    box-shadow: gray 2px 2px 2px 2px;
    width: 164px;
     margin-left: 137px;
    float: left !important;
    text-align: center;
}
.title-reports h2.ant-typography, .ant-typography h2{
margin-left: 200px !important;
margin-bottom: 0.5em;
color: #383838;
font-weight: 600;
font-size: 30px;
line-height: 1.35;
}
.pie-list {
  min-width: 200px;
  margin: 0 -7px !important;
  padding: 0;
  list-style: none;
  text-align: left;
 }
 .demo-loadmore-list .ant-list-item-no-flex {
  display: table !important;
}
/* .demo-loadmore-list  .ant-btn {
  line-height: 47px !important;
} */
/* .pop-over{
  padding-left: 400px !important
} */
 
 .spin-card {
 
  text-align: center;
  height:200px;
  padding-top: 80px !important;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  width: 50px;
  height: 50px;}
  .ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    width: 50px;
    height: 50px;}
    .ant-spin-dot-item:nth-child(4) {
      bottom: 0;
      left: 0;
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s;
      width: 50px;
      height: 50px;}
      .switch-over{
        margin-right:20px !important; 
      }